import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Button from '../../Button';
import { bannerHeaderVar } from '../../../utils/framerVariants';

const LandingPEO = ( { headings } ) => {
	const [ index, setIndex ] = useState( 0 );

	useEffect( () => {

		const interval = setInterval( () => {
			setIndex( prev => prev < headings.length - 1 ? prev + 1 : 0 );
		}, 5000 );

		return () => {
			clearInterval( interval );
		};
	}, [ headings.length, setIndex ] );

	return (
<div>
<section id="hero-5" class="bg--scroll hero-section">
				<div class="container">
					<div class="row d-flex align-items-center">


						<div class="col-md-6">
							<div class="hero-5-txt wow fadeInRight">


								<h2 class="s-58 w-700">The growth accelerator for your startup</h2>

								<p class="p-lg">Mauris donec turpis suscipit sapien ociis sagittis sapien tempor a volute
									ligula and aliquet tortor
								</p>

								<a href="#banner-7" class="btn r-04 btn--theme hover--theme">Get started for free</a>

							</div>
						</div>

						<div class="col-md-6">
							<div class="hero-5-img wow fadeInLeft">
								<img class="img-fluid" src="../media/img-18.png" alt="hero-image"/>
							</div>
						</div>


					</div>
				</div>


				<div class="wave-shape-bottom">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170"><path fill-opacity="1" d="M0,160L120,160C240,160,480,160,720,138.7C960,117,1200,75,1320,53.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
				</div>


</section>

<div id="statistic-1" class="py-100 statistic-section division">
    <div class="container">
        <div class="statistic-1-wrapper">
            <div class="row justify-content-md-center row-cols-1 row-cols-md-3">
                <div class="col">
                    <div id="sb-1-1" class="wow fadeInUp">
                        <div class="statistic-block">
                            <div class="statistic-block-digit text-center">
                                <h2 class="s-46 statistic-number"><span class="count-element">89</span>k</h2>
                            </div>
                            <div class="statistic-block-txt color--grey">
                                <p class="p-md">Porta justo integer and velna vitae auctor</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div id="sb-1-2" class="wow fadeInUp">
                        <div class="statistic-block">
                            <div class="statistic-block-digit text-center">
                                <h2 class="s-46 statistic-number"><span class="count-element">76</span>%</h2>
                            </div>
                            <div class="statistic-block-txt color--grey">
                                <p class="p-md">Ligula magna suscipit vitae and rutrum</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div id="sb-1-3" class="wow fadeInUp">
                        <div class="statistic-block">
                            <div class="statistic-block-digit text-center">
                                <h2 class="s-46 statistic-number">
                                    <span class="count-element">4</span>.<span class="count-element">93</span>
                                </h2>
                            </div>
                            <div class="statistic-block-txt color--grey">
                                <p class="p-md">Sagittis congue augue egestas an egestas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr class="divider"></hr>
<section id="features-11" class="pt-100 features-section division">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
                <div class="section-title mb-70">
                    <h2 class="s-50 w-700">We make your business gain more revenue at a glance</h2>
                    <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
                </div>
            </div>
        </div>
        <div class="fbox-wrapper">
            <div class="row row-cols-1 row-cols-md-2 rows-3">
                <div class="col">
                    <div class="fbox-11 fb-1 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-graphics"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Market Research</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-11 fb-2 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-idea"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">User Experience</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-11 fb-3 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-graphic"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Digital Marketing</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-11 fb-4 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-wireframe"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Web Development</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-11 fb-5 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-trophy"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Brand Design Identity</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-11 fb-6 wow fadeInUp">
                        <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                                <div class="shape-ico color--theme">
                                    <span class="flaticon-search-engine-1"></span>
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">SEO & SMM Services</h6>
                            <p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr class="divider"></hr>
<section id="lnk-1" class="pt-100 ws-wrapper content-section">
    <div class="container">
        <div class="bc-5-wrapper bg--02 hidd bg--scroll r-16">
            <div class="section-overlay">
                <div class="row justify-content-center">
                    <div class="col-md-11 col-lg-9">
                        <div class="section-title wow fadeInUp mb-60">
                            <h2 class="s-50 w-700">Build a customer-centric marketing strategy</h2>
                            <p class="p-xl">Aliquam a augue suscipit luctus neque purus ipsum neque diam dolor primis libero tempus, blandit and cursus varius and magnis sodales</p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="bc-5-img bc-5-tablet img-block-hidden video-preview wow fadeInUp">
                            <a class="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8">
                                <div class="video-btn video-btn-xl bg--theme">
                                    <div class="video-block-wrapper"><span class="flaticon-play-button"></span></div>
                                </div>
                            </a>
                            <img class="img-fluid" src="images/tablet-01.png" alt="content-image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="features-2" class="py-100 features-section division">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
                <div class="section-title mb-80">
                    <h2 class="s-50 w-700">Everything in One Place</h2>
                    <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
                </div>
            </div>
        </div>
        <div class="fbox-wrapper text-center">
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                    <div class="fbox-2 fb-1 wow fadeInUp">
                        <div class="fbox-img gr--whitesmoke h-175">
                            <img class="img-fluid" src="images/f_01.png" alt="feature-image"/>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Intuitive Dashboard</h6>
                            <p>Luctus egestas augue undo ultrice aliquam in lacus congue dapibus</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-2 fb-2 wow fadeInUp">
                        <div class="fbox-img gr--whitesmoke h-175">
                            <img class="img-fluid" src="images/f_05.png" alt="feature-image"/>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Effortless Integration</h6>
                            <p>Tempor laoreet augue undo ultrice aliquam in lacusq luctus feugiat</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="fbox-2 fb-3 wow fadeInUp">
                        <div class="fbox-img gr--whitesmoke h-175">
                            <img class="img-fluid" src="images/f_02.png" alt="feature-image"/>
                        </div>
                        <div class="fbox-txt">
                            <h6 class="s-22 w-700">Real-time Analytics</h6>
                            <p>Egestas luctus augue undo ultrice aliquam in lacus feugiat cursus</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr class="divider"></hr>
<section id="lnk-2" class="pt-100 ct-02 content-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6">
                <div class="img-block left-column wow fadeInRight">
                    <img class="img-fluid" src="images/img-07.png" alt="content-image"/>
                </div>
            </div>
            <div class="col-md-6">
                <div class="txt-block right-column wow fadeInLeft">
                    <span class="section-id">Strategies That Work</span>
                    <h2 class="s-46 w-700">Right strategies & implementations</h2>
                    <p>Sodales tempor sapien quaerat ipsum undo congue laoreet turpis neque auctor turpis vitae dolor luctus placerat magna and ligula cursus purus vitae purus an ipsum suscipit</p>
                    <p class="mb-0">Nemo ipsam egestas volute turpis egestas ipsum and purus sapien ultrice an aliquam quaerat ipsum augue turpis sapien cursus congue magna purus quaerat at ligula purus egestas magna cursus undo varius purus magnis sapien quaerat</p>
                    <div class="txt-block-tra-link mt-25">
                        <a href="#integrations-1" class="tra-link ico-20 color--theme">Friendly with others <span class="flaticon-next"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="py-100 ct-01 content-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 order-last order-md-2">
                <div class="txt-block left-column wow fadeInRight">
                    <div class="txt-box">
                        <h5 class="s-24 w-700">Solution that grows with you</h5>
                        <p>Sodales tempor sapien quaerat ipsum undo congue laoreet turpis neque auctor turpis vitae dolor luctus placerat magna and ligula cursus purus vitae purus an ipsum suscipit</p>
                    </div>
                    <div class="txt-box mb-0">
                        <h5 class="s-24 w-700">Connect your data sources</h5>
                        <p>Tempor sapien sodales quaerat ipsum undo congue laoreet turpis neque auctor turpis vitae dolor luctus placerat magna and ligula cursus purus an ipsum vitae suscipit purus</p>
                        <ul class="simple-list">
                            <li class="list-item">
                                <p>Tempor sapien quaerat an ipsum laoreet purus and sapien dolor an ultrice ipsum aliquam undo congue dolor cursus</p>
                            </li>
                            <li class="list-item">
                                <p class="mb-0">Cursus purus suscipit vitae cubilia magnis volute egestas vitae sapien turpis ultrice auctor congue magna placerat</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-first order-md-2">
                <div class="img-block right-column wow fadeInLeft">
                    <img class="img-fluid" src="images/img-06.png" alt="content-image"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg--white-300 py-100 ct-02 content-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6">
                <div class="img-block left-column wow fadeInRight">
                    <img class="img-fluid" src="images/img-02.png" alt="content-image"/>
                </div>
            </div>
            <div class="col-md-6">
                <div class="txt-block right-column wow fadeInLeft">
                    <span class="section-id color--grey">Easy Integration</span>
                    <h2 class="s-46 w-700">Plug your essential tools in few clicks</h2>
                    <ul class="simple-list">
                        <li class="list-item">
                            <p>Cursus purus suscipit vitae cubilia magnis volute egestas vitae sapien turpis sodales magna undo aoreet primis</p>
                        </li>
                        <li class="list-item">
                            <p class="mb-0">Tempor sapien quaerat an ipsum laoreet purus and sapien dolor an ultrice ipsum aliquam undo congue dolor cursus purus congue and ipsum purus sapien a blandit</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-100 ct-01 content-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 order-last order-md-2">
                <div class="txt-block left-column wow fadeInRight">
                    <span class="section-id color--grey">Productivity Focused</span>
                    <h2 class="s-46 w-700">Achieve more with better workflows</h2>
                    <p>Sodales tempor sapien quaerat ipsum undo congue laoreet turpis neque auctor turpis vitae dolor luctus placerat magna and ligula cursus purus vitae purus an ipsum suscipit</p>
                    <ul class="simple-list">
                        <li class="list-item">
                            <p>Sapien quaerat tempor an ipsum laoreet purus and sapien dolor an ultrice ipsum aliquam undo congue cursus dolor</p>
                        </li>
                        <li class="list-item">
                            <p class="mb-0">Purus suscipit cursus vitae cubilia magnis volute egestas vitae sapien turpis ultrice auctor congue magna placerat</p>
                        </li>
                    </ul>
                    <div class="txt-block-tra-link mt-25">
                        <a href="#features-5" class="tra-link ico-20 color--theme">All-in-one platform <span class="flaticon-next"></span></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-first order-md-2">
                <div class="img-block right-column wow fadeInLeft">
                    <img class="img-fluid" src="images/img-05.png" alt="content-image"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="features-13" class="shape--bg shape--white-500 pt-100 features-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-7 order-last order-md-2">
                <div class="fbox-13-wrapper wow fadeInRight">
                    <div class="row">
                        <div class="col-md-6">
                            <div id="fb-12-1" class="fbox-12 bg--white-100 block-shadow r-12 mb-30">
                                <div class="fbox-ico ico-50">
                                    <div class="shape-ico color--theme">
                                        <span class="flaticon-layers-1"></span>
                                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="fbox-txt">
                                    <h5 class="s-19 w-700">Content Marketing</h5>
                                    <p>Porta semper lacus and cursus feugiat at primis ultrice a ligula auctor</p>
                                </div>
                            </div>
                            <div id="fb-12-2" class="fbox-12 bg--white-100 block-shadow r-12">
                                <div class="fbox-ico ico-50">
                                    <div class="shape-ico color--theme">
                                        <span class="flaticon-tutorial"></span>
                                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="fbox-txt">
                                    <h5 class="s-19 w-700">Video Marketing</h5>
                                    <p>Porta semper lacus and cursus feugiat at primis ultrice a ligula auctor</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div id="fb-12-3" class="fbox-12 bg--white-100 block-shadow r-12 mb-30">
                                <div class="fbox-ico ico-50">
                                    <div class="shape-ico color--theme">
                                        <span class="flaticon-pay-per-click"></span>
                                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="fbox-txt">
                                    <h5 class="s-19 w-700">Pay Per Click (PPC)</h5>
                                    <p>Porta semper lacus and cursus feugiat at primis ultrice a ligula auctor</p>
                                </div>
                            </div>
                            <div id="fb-12-4" class="fbox-12 bg--white-100 block-shadow r-12">
                                <div class="fbox-ico ico-50">
                                    <div class="shape-ico color--theme">
                                        <span class="flaticon-taxes"></span>
                                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="fbox-txt">
                                    <h5 class="s-19 w-700">Business Analytics</h5>
                                    <p>Porta semper lacus and cursus feugiat at primis ultrice a ligula auctor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 order-first order-md-2">
                <div class="txt-block left-column wow fadeInLeft">
                    <span class="section-id">One-Stop Solution</span>
                    <h2 class="s-44 w-700">Smart solutions, real-time results</h2>
                    <p>Sodales tempor sapien quaerat ipsum and congue undo laoreet turpis neque auctor turpis vitae dolor luctus placerat magna ligula and cursus vitae</p>
                    <ul class="simple-list">
                        <li class="list-item">
                            <p>Tempor sapien quaerat undo ipsum laoreet diam purus sapien a dolor ociis ultrice ipsum aliquam</p>
                        </li>
                        <li class="list-item">
                            <p class="mb-0">Cursus purus suscipit vitae cubilia magnis diam volute egestas sapien ultrice auctor</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="features-5" class="pt-100 features-section division">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
                <div class="section-title mb-70">
                    <h2 class="s-50 w-700">Reach your audience through social media marketing</h2>
                    <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
                </div>
            </div>
        </div>
        <div class="fbox-wrapper text-center">
            <div class="row d-flex align-items-center">
                <div class="col-md-6">
                    <div class="fbox-5 fb-1 gr--smoke r-16 wow fadeInUp">
                        <div class="fbox-txt order-last order-md-2">
                            <h5 class="s-26 w-700">Marketing Integrations</h5>
                            <p>Aliquam a augue suscipit luctus diam neque purus ipsum neque and dolor primis libero</p>
                        </div>
                        <div class="fbox-5-img order-first order-md-2">
                            <img class="img-fluid" src="images/f_06.png" alt="feature-image"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="fbox-5 fb-2 gr--smoke r-16 wow fadeInUp">
                        <div class="fbox-5-img">
                            <img class="img-fluid" src="images/f_04.png" alt="feature-image"/>
                        </div>
                        <div class="fbox-txt">
                            <h5 class="s-26 w-700">Productivity Focused</h5>
                            <p>Aliquam a augue suscipit luctus diam neque purus ipsum neque and dolor primis libero</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-100 ct-02 content-section division">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
                <div class="section-title mb-80">
                    <h2 class="s-50 w-700">The Complete Solutions</h2>
                    <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-6">
                <div class="img-block left-column wow fadeInRight">
                    <img class="img-fluid" src="images/img-03.png" alt="content-image"/>
                </div>
            </div>
            <div class="col-md-6">
                <div class="txt-block right-column wow fadeInLeft">
                    <div class="cbox-4">
                        <div class="box-title">
                            <span class="flaticon-paper-sizes color--theme"></span>
                            <h5 class="s-22 w-700">Built-in automation</h5>
                        </div>
                        <div class="cbox-4-txt">
                            <p>Quaerat sodales sapien blandit purus primis purus ipsum cubilia laoreet augue luctus and magna dolor luctus egestas an ipsum sapien primis vitae volute and magna turpis</p>
                        </div>
                    </div>
                    <div class="cbox-4">
                        <div class="box-title">
                            <span class="flaticon-layers-1 color--theme"></span>
                            <h5 class="s-22 w-700">Automatic workflows</h5>
                        </div>
                        <div class="cbox-4-txt">
                            <p>Quaerat sodales sapien blandit purus primis purus ipsum cubilia laoreet augue luctus and magna dolor luctus egestas an ipsum sapien primis vitae volute and magna turpis</p>
                        </div>
                    </div>
                    <div class="cbox-4">
                        <div class="box-title">
                            <span class="flaticon-pie-chart color--theme"></span>
                            <h5 class="s-22 w-700">Real-time analytics</h5>
                            <p class="mb-0">Quaerat sodales sapien blandit purus primis purus ipsum cubilia laoreet augue luctus and magna dolor luctus egestas an ipsum sapien primis vitae volute and magna turpis</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-100 ct-08 content-section division">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
                <div class="section-title mb-70">
                    <h2 class="s-50 w-700">Discover insights across all your data with Martex</h2>
                    <p class="s-21">Ligula risus auctor tempus magna feugiat lacinia.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="img-block wow fadeInUp">
                    <img class="img-fluid" src="images/img-19.png" alt="video-preview"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="img-block-btn text-center wow fadeInUp">
                    <a href="#integrations-1" class="btn r-04 btn--tra-black hover--theme">Monitor your activity</a>
                    <ul class="advantages ico-15 clearfix">
                        <li><p>Free 14 days trial</p></li>
                        <li class="advantages-links-divider"><p><span class="flaticon-minus"></span></p></li>
                        <li><p>Exclusive Support</p></li>
                        <li class="advantages-links-divider"><p><span class="flaticon-minus"></span></p></li>
                        <li><p>No Fees</p></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-100 ct-03 content-section division">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-5 order-last order-md-2">
                <div class="txt-block left-column wow fadeInRight">
                    <span class="section-id">Engagement Analytics</span>
                    <h2 class="s-46 w-700">Data-driven digital marketing</h2>
                    <ul class="simple-list">
                        <li class="list-item">
                            <p>Tempor sapien quaerat undo ipsum laoreet purus and sapien dolor ociis ultrice ipsum aliquam undo congue dolor cursus congue varius magnis</p>
                        </li>
                        <li class="list-item">
                            <p class="mb-0">Cursus purus suscipit vitae cubilia magnis diam volute egestas sapien ultrice auctor</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-7 order-first order-md-2">
                <div class="img-block right-column wow fadeInLeft">
                    <img class="img-fluid" src="images/img-14.png" alt="content-image"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="integrations-1" class="py-100 integrations-section">
<div class="container">

					<div class="row justify-content-center">
						<div class="col-md-8">
							<div class="section-title mb-70">


								<h2 class="s-50 w-700">Automate your workflow with our integrations</h2>


								<p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>

							</div>
						</div>
					</div>



					<div class="integrations-1-wrapper">
						<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 rows-2">



		 					<div class="col">
		 						<a href="#" class="in_tool it-1 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-1.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Zapier</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-2 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-2.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Google Analytics</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-3 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-3.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Amplitude</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-4 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-4.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Hubspot</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-5 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-5.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">MailChimp</h6>
										<p class="p-sm">Import data</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-6 r-12 mb-30 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-6.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Slack</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-7 r-12 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-7.png" alt="brand-logo"/>
										</div>
									</div>


									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Dropbox</h6>
										<p class="p-sm">Import data</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-8 r-12 wow fadeInUp">


		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-8.png" alt="brand-logo"/>
										</div>
									</div>

									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Trello</h6>
										<p class="p-sm">Share findings</p>
									</div>

		 						</a>
		 					</div>



		 					<div class="col">
		 						<a href="#" class="in_tool it-9 r-12 wow fadeInUp">

		 							<div class="in_tool-logo-wrap">
		 								<div class="in_tool-logo ico-60">
											<img class="img-fluid" src="images/png_icons/tool-9.png" alt="brand-logo"/>
										</div>
									</div>

									<div class="in_tool-txt">
										<h6 class="s-20 w-700">Google Drive</h6>
										<p class="p-sm">Import data</p>
									</div>

		 						</a>
		 					</div>


		 				</div>
		 			</div>



					<div class="row">
						<div class="col">
							<div class="more-btn text-center mt-60 wow fadeInUp">
								<a href="integrations.html" class="btn btn--tra-black hover--theme">View all integrations</a>
							</div>
						</div>
					</div>


				</div>
			</section>




			<section id="reviews-1" class="py-100 shape--06 shape--gr-whitesmoke reviews-section">
				<div class="container">



					<div class="row justify-content-center">
						<div class="col-md-10 col-lg-9">
							<div class="section-title mb-70">


								<h2 class="s-50 w-700">Our Happy Customers</h2>


								<p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>

							</div>
						</div>
					</div>



					<div class="row">
						<div class="col">
							<div class="owl-carousel owl-theme reviews-1-wrapper">



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>Etiam sapien sagittis congue augue a massa varius egestas ultrice varius magna
										   a tempus aliquet undo cursus suscipit
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-1.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Scott Boxer</h6>
												<p class="p-sm">@scott_boxer</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>At sagittis congue augue diam egestas magna an ipsum vitae purus ipsum primis
										   and cubilia laoreet augue egestas a luctus donec ltrice ligula porta augue magna
										   suscipit lectus gestas
										</p>


										<div class="author-data clearfix">

											<div class="review-avatar">
												<img src="images/review-author-2.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Joel Peterson</h6>
												<p class="p-sm">Internet Surfer</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>Mauris gestas magnis a sapien etiam sapien congue an augue egestas and ultrice
										   vitae purus diam an integer congue magna ligula egestas magna suscipit
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-3.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Marisol19</h6>
												<p class="p-sm">@marisol19</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>Mauris donec a magnis sapien etiam pretium a congue augue volutpat lectus
										   aenean magna and undo mauris lectus laoreet tempor egestas rutrum
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-4.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Leslie D.</h6>
												<p class="p-sm">Web Developer</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">

										<p>An augue cubilia undo laoreet magna suscipit egestas ipsum lectus purus ipsum
										   and primis augue an ultrice ligula egestas suscipit a lectus gestas auctor tempus
										   feugiat impedit
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-5.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Jennifer Harper</h6>
												<p class="p-sm">App Developer</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>An augue cubilia laoreet undo magna ipsum semper suscipit egestas magna ipsum
										   ligula a vitae purus and ipsum primis cubilia magna suscipit
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-6.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Jonathan Barnes</h6>
												<p class="p-sm">jQuery Programmer</p>
											</div>

										</div>

									</div>

								</div>


								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>


									<div class="review-txt">


										<p>Augue egestas porta tempus volutpat egestas augue cubilia laoreet a magna
										   suscipit luctus dolor blandit vitae purus neque tempus an aliquet porta gestas
										   rutrum blandit vitae
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-7.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Mike Harris</h6>
												<p class="p-sm">Graphic Designer</p>
											</div>

										</div>

									</div>

								</div>



								<div class="review-1 bg--white-100 block-shadow r-08">


		 							<div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

									<div class="review-txt">

										<p>Augue at vitae purus tempus egestas volutpat augue undo cubilia laoreet magna
										   suscipit luctus dolor blandit at purus tempus feugiat impedit
										</p>


										<div class="author-data clearfix">


											<div class="review-avatar">
												<img src="images/review-author-8.jpg" alt="review-avatar"/>
											</div>


											<div class="review-author">
												<h6 class="s-18 w-700">Evelyn Martinez</h6>
												<p class="p-sm">WordPress Consultant</p>
											</div>

										</div>

									</div>

								</div>


							</div>
						</div>
					</div>


				</div>
			</section>


			<hr class="divider"></hr>


			<section id="banner-7" class="banner-section">
				<div class="banner-overlay py-100">
					<div class="container">


						<div class="banner-7-wrapper">
							<div class="row justify-content-center">



								<div class="col-md-8">
									<div class="banner-7-txt text-center">


										<h2 class="s-50 w-700">Starting with Martex is easy, fast and free</h2>


										<div class="btns-group">
											<a href="signup-1.html" class="btn r-04 btn--theme hover--theme">Get srarted - it's free
											</a>
											<a href="faqs.html" class="btn r-04 btn--tra-black hover--theme">Read the FAQs</a>
										</div>


										<p class="btn-txt ico-15">Free for 14 days - no credit card required.</p>

									</div>
								</div>


							</div>
						</div>


					</div>
				</div>
			</section>


			<hr class="divider"></hr>

			</div>



	);
};

LandingPEO.propTypes = { headings: PropTypes.array };

export default LandingPEO;
